import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const Login = (props) => {

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const response = await fetch('https://climateapi.vimgrep.in/login', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                username,
                password
                }),
                credentials: 'include'
            })
            const code = response.status
            const body = await response.json()
            if(code === 200) {
                props.onLogin()
            } else {
                alert(body.message || 'Something went wrong!!')
            }
        } catch(e) {
            alert(`Something went wrong [${e}]!!`)
        }
    }


  return (
    <>
    <div className='login-page-heading'><h1>CLIMATE RECIPE OPTIMISER</h1></div>
    <Form onSubmit={handleSubmit} className="main-form">
      <Form.Group className="mb-3 form-group" controlId="uname">
        <Form.Label className="login-page-label">Username</Form.Label>
        <Form.Control type="username" placeholder="Enter Username" value={username} onChange={e => setUsername(e.target.value)} className="login-page-input"/>
      </Form.Group>

      <Form.Group className="mb-3 form-group" controlId="pass">
        <Form.Label className="login-page-label">Password </Form.Label>
        <Form.Control type="password" placeholder="Enter Password" value={password} onChange={e => setPassword(e.target.value)} className="login-page-input"/>
      </Form.Group>
      <Button variant="danger" type="submit" className="button-general mouseover-primary">
        LOGIN
      </Button>
    </Form>
    </>
  );
}

export default Login;