import './App.css';
import Login from './Login';
import { useState } from 'react';
import Cookies from 'js-cookie';
import ClimateRecipe from './ClimateRecipe';

const FA_COOKIE_KEY = 'fa'

function App() {

  const [didLogin, setDidLogin] = useState(false)

  const handleLogin = () => {
    setDidLogin(true)
  }

  const handleLogout = () => {
    Cookies.remove(FA_COOKIE_KEY, {path: '/', domain: 'vimgrep.in'})
    setDidLogin(false)
  }

  const lCookie = Cookies.get(FA_COOKIE_KEY) || ''
  return (
    <div className="App">
      { (!didLogin && lCookie.length === 0) ?
        <Login onLogin={handleLogin}/> : <ClimateRecipe onLogout={handleLogout}/>
      }
    </div>
  );
}

export default App;
